import React from 'react'
import Img from '../assets/logo.png'

const Logo = () => {
  return (
    <div
      style={{
        // borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        boxShadow: '0 1px 3px #0000001a, 0 1px 2px #0000000f',
        padding: '10px',
        // border: '1px solid #00a6ff',
      }}
    >
      <img
        src={Img}
        width={150}
        height={50}
        style={{
          // borderRadius: '20px',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      />
    </div>
  )
}

export default Logo
