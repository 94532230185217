// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'
import CodeSnippet1 from '../../assets/images/code-snippet-1.png'
import Q1 from '../../assets/q1.png'
import Q2 from '../../assets/q2.png'
import Q3 from '../../assets/q3.jpg'
import Q4 from '../../assets/q4.jpeg'
export const react: Topic = {
  topic: 'React',
  level: 'Intermediate',
  totalQuestions: 10,
  totalScore: 95,
  totalTime: 600,
  questions: [
    {
      question: 'What does this sign mean?',
      image: Q1,
      choices: [
        'This lane is for buses only',
        'This lane is for trucks only',
        'This lane is for bicycles only',
        'This lane is not for bicycles',
      ],
      type: 'MCQs',
      correctAnswers: ['This lane is for bicycles only'],
      score: 10,
    },

    {
      question: 'What does this sign mean?',
      image: Q2,
      choices: [
        'There is a community zone ahead ; Special risk of pedestrians in the area',
        'There is a construction zone ahead : Special risk of workers in the area',
        'There is hospital zone ahead : Special risk of patients in the area',
        'There is police station ahead : Specials risk of pedestrians in the area',
      ],
      type: 'MCQs',
      correctAnswers: [
        'There is a community zone ahead ; Special risk of pedestrians in the area',
      ],
      score: 10,
    },
    {
      question: 'What does this sign mean?',
      image: Q3,
      choices: [
        'Shows the upcoming right turn on freeway',
        'Shows the upcoming exits on freeway',
        'Shows the upcoming town and cities',
        'Shows the upcoming intersection',
      ],
      type: 'MCQs',
      correctAnswers: ['Shows the upcoming exits on freeway'],
      score: 10,
    },
    {
      question: 'What does this sign mean?',
      image: Q4,
      choices: [
        'Shows the freeway interchange between two highways with the distance as their mentioned numbers from the beginning',
        'Shows the upcoming town with distance as their mentioned numbers from the beginning',
        'Show the route to the mentioned destination',
        'Shows only exits from the ramp or freeways',
      ],
      type: 'MCQs',
      correctAnswers: [
        'Shows the freeway interchange between two highways with the distance as their mentioned numbers from the beginning',
      ],
      score: 10,
    },
  ],
}
